import { useSelector } from 'react-redux';
import { selectLegalDocuments } from '../store/selectors/entities';

const getLegalDocuments = () => {
  const documents = useSelector(selectLegalDocuments);

  if (!documents) {
    return { links: [] };
  }

  const links = documents?.reduce((acc, { url, type }) => {
    acc[type] = { url };
    return acc;
  }, {});

  return { links };
};

export default getLegalDocuments;
