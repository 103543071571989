import React from 'react';
import Prismic from 'prismic-javascript';

import { Client } from '../../config/prismic';

export const TYPE_POST = 'post';
export const TYPE_FAQ = 'frequentlyaskedquestion';
export const TYPE_SUBSCRIPTION_PLAN = 'subscriptionplan';
export const TYPE_SUBSCRIPTION_PLAN_LEVEL = 'subscriptionplanlevel';
export const TYPE_DOCUMENT_LINKS = 'documentlinks';
export const TYPE_DOCUMENT_LINKS_BY_COUNTRY = 'documentlinksbycountry';
export const TYPE_ADDONS = 'descriptions';

export function getAllPosts({ tags }) {
  const isTagsSelected = tags.length > 0;

  const predicates = [
    Prismic.Predicates.at('document.type', TYPE_POST),
    Prismic.Predicates.any('my.post.tag', tags),
  ];

  const [documents, setDocuments] = React.useState(null);
  const [hasMore, setHasMore] = React.useState(false);
  const pageRef = React.useRef(1);

  // eslint-disable-next-line consistent-return
  const fetchPosts = async ({ more }) => {
    if (!isTagsSelected) {
      return setDocuments(null);
    }
    const page = more ? pageRef.current + 1 : pageRef.current;

    const response = await Client.query(predicates, { page, pageSize: 6 });
    if (response) {
      setDocuments(more && documents ? documents.concat(response.results) : response.results);
      setHasMore(response.page < response.total_pages);
      pageRef.current = response.page;
    }
  };

  React.useEffect(() => {
    pageRef.current = 1;
    fetchPosts({ more: false });
  }, [tags]);

  return {
    documents,
    fetchMore: () => fetchPosts({ more: hasMore }),
    hasMore,
  };
}

export const getTopPosts = () => {
  const predicates = [
    Prismic.Predicates.at('document.type', TYPE_POST),
    Prismic.Predicates.at('document.tags', ['top']),
  ];

  const [documents, setDocuments] = React.useState(null);

  React.useEffect(() => {
    const fetchPosts = async () => {
      const response = await Client.query(predicates);
      if (response) {
        setDocuments(response.results);
      }
    };
    fetchPosts();
  }, []);

  return { documents };
};

export const getSubscriptionPlanLevel = (levelId) => {
  const [document, setDocument] = React.useState(null);

  React.useEffect(() => {
    const fetch = async () => {
      const response = await Client.getByUID(TYPE_SUBSCRIPTION_PLAN_LEVEL, levelId);
      if (response) {
        setDocument(response);
      }
    };
    fetch();
  }, []);

  return { document };
};

const getDocumentsAll = () =>
  Client.query(Prismic.Predicates.at('document.type', TYPE_DOCUMENT_LINKS));

export const getDocumentLinks = () => {
  try {
    const [links, setLinks] = React.useState(null);
    const mountedRef = React.useRef(true);

    const setResult = (result) => {
      if (mountedRef.current) {
        setLinks(result);
      }
    };

    React.useEffect(() => {
      const fetch = async () => {
        const [responseByRegions] = await Promise.all([getDocumentsAll()]);
        if (responseByRegions.results) {
          const result = responseByRegions.results[0].data.links[0];
          setResult(result);
        }
      };
      fetch();
      return () => {
        mountedRef.current = false;
      };
    }, []);
    return { links };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('document error', e);
  }
  return {};
};
