import Prismic from 'prismic-javascript';
import { isAddonInstance } from '../src/helpers/instanceHandler';

export const apiEndpoint = 'https://newshop.cdn.prismic.io/api/v2';
export const apiEndpointAddons = 'https://addons.cdn.prismic.io/api/v2';
export const accessToken = ''; // This is where you would add your access token for a Private repository

export const shopClient = Prismic.client(apiEndpoint, { accessToken });
export const addonsClient = Prismic.client(apiEndpointAddons, { accessToken });

export const Client = isAddonInstance ? addonsClient : shopClient;
