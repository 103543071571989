import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import { useSelector } from 'react-redux';
import { countries } from 'countries-list';
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing-3';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import Loader from '../../components/loader';
import { getDocumentLinks } from '../../hooks/prismic';
import { isAddonInstance } from '../../helpers/instanceHandler';
import styles from './documents.module.scss';

import useLegalDocumentLinks from '../../hooks/useLegalDocumentLinks';

import { selectUserCountry } from '../../store/selectors/global';
import { pageLinks } from '../../helpers/navigation';

const Documents = ({ pageContext }) => {
  const { titleId, documentId } = pageContext;
  const [fullSize, setFullSize] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const intl = useIntl();
  const userCountry = useSelector(selectUserCountry);
  const countryData = (userCountry && countries[userCountry]) || {};

  const getDocs =
    isAddonInstance ||
    (documentId === 'ibp_agreement' && process.env.GATSBY_INSTANCE_NAME.includes('onlineLine'))
      ? getDocumentLinks
      : useLegalDocumentLinks;

  const { links } = getDocs(countryData && countryData.name);

  const title = intl.formatMessage({ id: titleId });

  const toggleFullSize = (e) => {
    e.preventDefault();
    setFullSize(!fullSize);
  };

  const url = links && links[documentId] && links[documentId].url;

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <>
          <div className={styles.header}>
            <div className={styles.closeLink}>
              <Link
                to={modal ? closeTo : pageLinks.home}
                state={{ noScroll: true }}
                className={styles.link}
              >
                <span>Close</span>
                <i className="icon-close" />
              </Link>
            </div>
          </div>

          <SEO title={title} />
          <h1 className={styles.title}>{title}</h1>
          <Loader isLoading={loading} isSmall={false} />
          <div
            className={clsx(styles.wrapper, { [styles.fullSize]: fullSize })}
            onDoubleClick={(e) => e.preventDefault()}
          >
            <div
              tabIndex="-1"
              role="presentation"
              className={styles.widthToggle}
              onClick={toggleFullSize}
            />
            {url && (
              <iframe
                onLoad={() => setLoading(false)}
                title={title}
                width="100%"
                height="100%"
                src={url}
                frameBorder="0"
                style={{ position: 'relative' }}
              />
            )}
          </div>
        </>
      )}
    </ModalRoutingContext.Consumer>
  );
};

Documents.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
  pageContext: PropTypes.shape({
    titleId: PropTypes.string.isRequired,
    documentId: PropTypes.string.isRequired,
    docPath: PropTypes.string,
    docUrl: PropTypes.string,
  }).isRequired,
};

export default Documents;
